<template>
  <div>
    AAAPAPAPAA
    <slot />
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>

